import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "black" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  color: "black",

  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "black",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "black",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "black",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgb(255 161 0)",
  color: "black",
  backgroundColor: "white",
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          paddingTop: 0,
          paddingBottom: 10,
          marginTop: 1,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    What are the use cases of MicroREC?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    The MicroREC digitalizes your microscope or slit lamp,
                    enabling multiple applications. Some of them are:
                    <Typography sx={{ padding: 1 }}>
                      {" "}
                      <b>- Telemedicine:</b> share the image live with other
                      doctors and discuss interesting cases, curate live
                      surgeries in real time or receive advice from your mentors
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <b>- Personal branding:</b> promote yourself by sharing
                      content on your social media, record microscopy imaging to
                      present in congresses, or share it online
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <b>- Self-improvement:</b> review your procedures to
                      improve technique
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <b>- Education:</b> livestream medical imaging to your
                      students or guide wet lab training remotely
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <b>- Remote support:</b> if you offer technical support to
                      doctors, you can enable your support team to remotely chat
                      with doctors in the OR
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      <b>- Legal reasons:</b> keep the data of your surgeries to
                      avoid legal issues in the future
                    </Typography>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel3bh-header"
                  className="AccordionSummary2"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    How to install the MicroREC?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id="" className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    You don’t need an engineer to install a recording system
                    anymore. Watch the video below to install the MicroREC in a
                    Microscope and in a slit lamp.
                  </Typography>
                  <iframe
                    maxWidth="560"
                    height="315"
                    src="https://www.youtube.com/embed/zrhQC_8qRWI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    maxWidth="560"
                    height="315"
                    src="https://www.youtube.com/embed/8r7mVQBs7pY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Typography sx={{ padding: 1 }}>
                    You can also read the detailed information about in your
                    user manual or{" "}
                    <a
                      href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                      className="links_post"
                    >
                      contact us{" "}
                    </a>
                    if you have any questions.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    With what microscopes and slitlamps does MicroREC work?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    We offer modular adapters that connect to 90% of microscopic
                    equipment on the market. You can check the compatibility
                    <a
                      href="/compatibility"
                      className="links_post"
                      target="_blank"
                    >
                      {" "}
                      here
                    </a>{" "}
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    My microscope/slitlamp does not appear on the compatibility
                    list. Can I still connect the MicroREC?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    We cannot guarantee that it will connect to it. But you can
                    check it further with{" "}
                    <a
                      href="https://wa.me/491782064226?text=Hi!%20My%20microscope/slitlamp%20does%20not%20appear%20on%20the%20compatibility%20list.%20Can%20I%20still%20connect%20the%20MicroREC?"
                      className="links_post"
                    >
                      our sales team
                    </a>{" "}
                    to see if we have updated it.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
