import * as React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Header from "./header";
import { Box } from "@mui/material";
import Humburger from "./burger";
import Footer from "./footer";

const styles = {
  buttonStyle: {
    background: "#FFA100",
    border: "0",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    color: "black",
    cursor: "pointer",
    flex: "0 0 auto",
    padding: "7px 20px",
    margin: "15px",
    fontWeight: "500",
  },
  declineButtonStyle: {
    background: "none",
    border: "0",
    color: "#FFA100",
    cursor: "pointer",
    flex: "0 0 auto",
    padding: "6px 16px",
    margin: "15px",
    fontWeight: "500",
    textDecoration: "underline",
  },
};

const Layout = ({ location, title, children, crumbLabel }) => {
  return (
    <div className="global-wrapper">
      <Box display={{ xs: "none", sm: "block" }}>
        <Header />
      </Box>
      <Box display={{ xs: "block", sm: "none" }}>
        <Humburger />
      </Box>
      <main>{children}</main>
      <Footer />
      <CookieConsent
        style={{
          justifyContent: "center",
          paddingBottom: "10px",
          textAlign: "justify",
          flexDirection: "column",
        }}
        contentStyle={{
          padding: 10,
          flex: "0",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "0px",
        }}
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-cookies"
        buttonWrapperClasses="button-wrapper"
        buttonStyle={styles.buttonStyle}
        declineButtonStyle={styles.declineButtonStyle}
        onAccept={() => {
          Cookies.set("gatsby-gdpr-cookies", "true");

          initializeAndTrack(location);
        }}
      >
        We use cookies on our website to give you the most relevant experience
        by remembering your preferences and repeat visits. By clicking “Accept",
        you consent to the use of cookies.{" "}
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#FFA100" }}
        >
          Learn More.
        </a>
      </CookieConsent>
    </div>
  );
};

export default Layout;
