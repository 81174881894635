import React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import logo from "/src/images/iso_color.png";
import Faqs1 from "../components/microrec/accordion/faqs1";
import Faqs2 from "../components/microrec/accordion/faqs2";
import Faqs3 from "../components/microrec/accordion/faqs3";
import Faqs4 from "../components/microrec/accordion/faqs4";
import Faqs5 from "../components/microrec/accordion/faqs5";
import Faqs6 from "../components/microrec/accordion/faqs6";
import Faqs7 from "../components/microrec/accordion/faqs7";

const FAQS = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="FAQS | Custom Surgical" />
      <div
        style={{
          maxWidth: "1200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ padding: "20px" }}>
          <h1 style={{ fontSize: "48px", marginTop: "3em" }}>FAQs & Support</h1>
          <h5>
            There is so much news that doubts are normal. (but we answer all of
            them for you!)
          </h5>

          <p style={{ fontSize: "16px" }}>
            If you have any issues or requests about purchasing, product, or
            installation, you can find your answers below. Your question is not
            listed? Then text your local distributor or contact us through the
            form below.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs1 style={{ top: "-3em", position: "relative" }}></Faqs1>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs2 style={{ top: "-3em", position: "relative" }}></Faqs2>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs3 style={{ top: "-3em", position: "relative" }}></Faqs3>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs4 style={{ top: "-3em", position: "relative" }}></Faqs4>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs5 style={{ top: "-3em", position: "relative" }}></Faqs5>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs6 style={{ top: "-3em", position: "relative" }}></Faqs6>
        </div>
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Faqs7 style={{ top: "-3em", position: "relative" }}></Faqs7>
        </div>
      </div>
    </Layout>
  );
};

export default FAQS;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
