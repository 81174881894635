import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "black" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  color: "black",

  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "black",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "black",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "black",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgb(255 161 0)",
  color: "black",
  backgroundColor: "white",
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          paddingTop: 0,
          paddingBottom: 10,
          marginTop: 1,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Are you 3D printing projects for free?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    Yes, they are open source files that you can download in
                    your house with no cost.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel3bh-header"
                  className="AccordionSummary2"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    How can I get the 3D-printed files?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id="" className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    You can download them{" "}
                    <a href="/open-source" className="links_post">
                      here
                    </a>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary3"
                  >
                    How can I print your 3D files?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="AccordionSummary3">
                  <Typography sx={{ padding: 1 }}>
                    You can download in any 3D-printing company near you.
                    <Typography sx={{ padding: 1 }}></Typography>
                    Alternatively, you can purchase ready-to-go printed files on
                    <a
                      className="links_post"
                      href="https://www.shapeways.com/shops/custom-surgical-1?T=1624623264088"
                    >
                      Shapeways
                    </a>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
