import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "black" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  color: "black",

  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "black",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "black",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "black",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgb(255 161 0)",
  color: "black",
  backgroundColor: "white",
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          paddingTop: 0,
          paddingBottom: 10,
          marginTop: 1,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Are there any safety requirements for using MicroREC?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    Yes, please follow the following rules:{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- Verify the
                    physical integrity of the product before using it.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- Use it only
                    indoors.<Typography sx={{ padding: 1 }}></Typography> -
                    Avoid exposure to the elements.
                    <Typography sx={{ padding: 1 }}></Typography> - Do not store
                    in humid environments and avoid contact with corrosive
                    liquids and materials, and oils.
                    <Typography sx={{ padding: 1 }}></Typography> - Do not
                    disassemble any parts outside of the user manual
                    specifications.<Typography sx={{ padding: 1 }}></Typography>{" "}
                    - Do not make any alterations to the product. This may
                    disarrange the optical elements and affect the product’s
                    performance. <Typography sx={{ padding: 1 }}></Typography>-
                    Do not place heavy objects on top of the product.
                    <Typography sx={{ padding: 1 }}></Typography> - Do not
                    expose the product or any of its components to fire or high
                    temperatures. <Typography sx={{ padding: 1 }}></Typography>-
                    Do not sit or stand above it.
                    <Typography sx={{ padding: 1 }}></Typography> - Keep out of
                    the reach of children.
                    <Typography sx={{ padding: 1 }}></Typography> - Do not use
                    excessive force when assembling the components, they are
                    designed to fit appropriately.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel3bh-header"
                  className="AccordionSummary2"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    How often should I clean the MicroREC?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id="" className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    If your MicroREC is <b>always connected</b> to your
                    equipment, you should take it off and clean the lenses{" "}
                    <b>every couple of months</b>.{" "}
                    <Typography sx={{ padding: 1 }}>
                      If you <b>disconnect the equipment often</b>, you should
                      increase the regularity of maintenance cleaning to
                      <b>once per month</b>.
                    </Typography>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary3"
                  >
                    How to clean your microscope recording system?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="AccordionSummary3">
                  <Typography sx={{ padding: 1 }}>
                    To do regular cleaning, use a special optics cloth.
                    <Typography sx={{ padding: 1 }}></Typography> -{" "}
                    <b>Do not use any corrosive liquids or materials</b> and
                    oils for cleaning, only pure water in small quantities.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- Never touch
                    the optics with your fingers.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- If the
                    product comes into contact with abundant liquid, wipe it
                    immediately with a soft cloth.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- If the liquid
                    enters an unreachable surface, place the product so that the
                    liquid may exit.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- Do not touch
                    or directly manipulate the lenses, mirrors, or other optical
                    components with your hands.{" "}
                    <Typography sx={{ padding: 1 }}></Typography>- If you detect
                    spots, dust, or residue, use a soft cloth to clean the
                    lenses.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Do I have to sterilize the equipment?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    Our optical systems do not get in direct contact with a
                    patient, and therefore, they do not require sterilization.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
