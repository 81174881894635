import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "black" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  color: "black",

  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "black",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "black",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "black",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgb(255 161 0)",
  color: "black",
  backgroundColor: "white",
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          paddingTop: 0,
          paddingBottom: 10,
          marginTop: 1,
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    How to order a MicroREC?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    You can buy directly from our{" "}
                    <a className="links_post" href="/store">
                      e-commerce
                    </a>{" "}
                    or through our official distributors, if the e-commerce is
                    not available on your country.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel3bh-header"
                  className="AccordionSummary2"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    In what countries do you have distributors?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id="" className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    We have distributors in more than 60 countries. You can ask
                    as about the contact information for the distributor via
                    Whatsapp{" "}
                    <a
                      href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                      target="t_blank"
                      className="links_post"
                    >
                      here
                    </a>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary3"
                  >
                    Do you ship worldwide?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="AccordionSummary3">
                  <Typography sx={{ padding: 1 }}>Yes.</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    How long does it take before I receive my order?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    <Typography sx={{ padding: 1 }}>
                      On average, we deliver all orders in 15 days.{" "}
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      Some orders from Asia and Latin America could take up to
                      30 days.
                    </Typography>
                    <Typography sx={{ padding: 1 }}>
                      If you have not received a tracking number after 7 days,
                      <a
                        href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                        className="links_post"
                      >
                        contact us here
                      </a>
                      .
                    </Typography>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography
                    sx={{ flexShrink: 1 }}
                    className="AccordionSummary2"
                  >
                    Can I order a Beam Splitter or other accessories separately?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="AccordionSummary2">
                  <Typography sx={{ padding: 1 }}>
                    Yes, you can order a Beam Splitter or an adapter through our{" "}
                    <a href="/store" className="links_post">
                      e-commerce
                    </a>{" "}
                    or distributors.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
